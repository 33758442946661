import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
// import { tenantDetails } from "pages/Details/constants/Constants";
import { getPlansList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import TimelineHeader from "components/Headers/SimpleHeader";
import { GetPlansList } from "constants/ReducerConstants/Constants";

function PlanList() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const plans_List = useSelector((state) => state.planReducer.plansList);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [planData, setPlanData] = useState({});
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  // const location = useLocation();

  useEffect(() => {
    // Fetch plans list on component mount
    getPlansList(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (plans_List?.code) {
      if (plans_List?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [plans_List]);

  // useEffect(() => {
  //   if (location.state && plans_List?.data?.Plan) {
  //     openPlanDetails(null, null, null, null, location.state.ID);
  //     setModal(true);
  //   }
  // }, [location.state, plans_List]);

  const openPlanDetails = (e, column, columnIndex, row, rowIndex) => {
    setPlanData(plans_List.data?.Plan[rowIndex]);
    toggle();
  };

  const Icon = ({ color = "info", icon = "check" }) => {
    return (
      <div
        className={`icon icon-shape bg-${color} text-white rounded-circle shadow mt-0 mr-2 ml-3`}
        style={{
          width: "20px",
          height: "20px",
        }}
      >
        <i className={`fa-solid fa-${icon} text-sm`}></i>
      </div>
    );
  };

  return (
    <>
      <TimelineHeader name="Plans" />

      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row md={12} lg={12} sm={12}>
              <Col md={10} lg={10} sm={10}>
                <h3 className="mb-0">Plans</h3>
              </Col>
              {/* <Col md={2} lg={2} sm={2}>
                <Button
                  outline
                  color="info"
                  onClick={() => navigate("/admin/add/plan")}
                >
                  Add plan
                </Button>
                <Button
                  outline
                  color="info"
                  onClick={() => navigate("/admin/editplanform")}
                >
                  Edit plan
                </Button>
              </Col> */}
            </Row>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div style={styles.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={styles.loaderColor}
                />
              </div>
            ) : error ? (
              <>
                <div style={styles.loaderContainer}>Something went wrong</div>
              </>
            ) : (
              <>
                {plans_List?.data?.Plan ? (
                  <ToolkitProvider
                    data={plans_List?.data?.Plan ? plans_List?.data?.Plan : []}
                    keyField="sequence"
                    columns={[
                      {
                        dataField: "ID",
                        text: "Sr No.",
                        sort: true,
                        events: { onClick: openPlanDetails },
                      },
                      {
                        dataField: "name",
                        text: "Plan Name",
                        sort: true,
                        events: { onClick: openPlanDetails },
                      },
                      {
                        dataField: "usdRate",
                        text: "Plan Price",
                        sort: true,
                        formatter: (cell) => {
                          // if (cell === 0) return <span>{cell}</span>;
                          // else
                          return <span>${cell}</span>;
                        },
                        events: { onClick: openPlanDetails },
                      },
                      {
                        dataField: "credits",
                        text: "Credits",
                        sort: true,
                        formatter: (cell) => {
                          return <span>{cell}</span>;
                        },
                        events: { onClick: openPlanDetails },
                      },
                      {
                        dataField: "campaignsLimit",
                        text: "Campaigns",
                        sort: true,
                        events: { onClick: openPlanDetails },
                      },
                      {
                        dataField: "duration",
                        text: "Duration",
                        sort: true,
                        formatter: (cell) => {
                          return <span>1 Month</span>;
                        },
                        events: { onClick: openPlanDetails },
                      },
                      {
                        dataField: "action",
                        text: "Action",
                        sort: true,
                        formatter: (cell) => {
                          return (
                            <i className="fa-solid fa-edit text-info ml-3 text-lg"></i>
                          );
                        },
                        events: {
                          onClick: (e, column, columnIndex, row, rowIndex) => {
                            navigate("/admin/plans/edit", {
                              state: row.ID,
                            });
                          },
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          // pagination={pagination}
                          bordered={false}
                          rowStyle={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <div style={styles.loaderContainer}>No Records Found!</div>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Container>

      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        contentClassName={""}
      >
        <ModalHeader className="mb--5" toggle={toggle}>
          {/* {planData.name} */}
        </ModalHeader>
        <p className="mt--2 text-center text-xl font-weight-bold">
          {planData.name}
        </p>
        <Divider variant="middle" className="mt--2" />
        <ModalBody className="text-center mt--3">
          <Row>
            <Col md={12} className="">
              <p className="my-1 text-lg font-weight-bolder">
                {/* {planData.usdRate === 0 ? "Free" : `$${planData.usdRate}`} */}
                ${planData.usdRate}
              </p>
              <p className="text-md font-weight-normal mt--1 mb-1">Per Month</p>

              <p className="mt--1 font-weight-normal">
                {planData.inrRate === 0 ? (
                  ""
                ) : (
                  <>
                    approx.{" "}
                    <i className="fa-solid fa-indian-rupee-sign fa-sm text-muted" />
                    {planData.inrRate}
                  </>
                )}
              </p>
              <Divider />
            </Col>

            <Col md={12} className="mt-4 d-flex">
              <Icon />
              <p>
                {planData.credits}
                <span> Credits </span>
              </p>
            </Col>
            {planData.ID !== 1 && (
              <Col md={12} className="d-flex">
                {planData.creditsCarryForward === "0" ? (
                  <Icon color="danger" icon="xmark" />
                ) : (
                  <Icon />
                )}
                <p>
                  {planData.creditsCarryForward} Credit
                  {planData.creditsCarryForward !== 0 && "s"} Carry Forward
                </p>
              </Col>
            )}
            <Col md={12} className="d-flex">
              {planData.campaignsLimit === "0" ? (
                <Icon color="danger" icon="xmark" />
              ) : (
                <Icon />
              )}
              <p>
                {planData.campaignsLimit}
                <span> Campaigns </span>
              </p>
            </Col>
            {planData.ID !== 1 && (
              <Col md={12} className="d-flex">
                {planData.subUsersLimit === "0" ? (
                  <Icon color="danger" icon="xmark" />
                ) : (
                  <Icon />
                )}
                <p>
                  {planData.subUsersLimit}
                  <span> Sub-User{planData.subUsersLimit !== 0 && "s"} </span>
                </p>
              </Col>
            )}
            <Col md={12} className="d-flex">
              <Icon />
              <p>{planData.feature1}</p>
            </Col>
            <Col md={12} className="d-flex">
              <Icon />
              <p>{planData.feature2}</p>
            </Col>
            {planData.ID === 1 && (
              <Col md={12} className="d-flex">
                <Icon />
                <p>Personalized Calendar, Emails</p>
              </Col>
            )}
            {planData.ID === 1 && (
              <Col md={12} className="d-flex">
                <Icon />
                <p>Upload Bulk Attendees</p>
              </Col>
            )}
            {planData.ID === 1 && (
              <Col md={12} className="d-flex">
                <Icon />
                <p>Send in 1 Click</p>
              </Col>
            )}
            {planData.ID !== 1 && (
              <Col md={12} className="d-flex">
                <Icon />
                <p>${planData.ratePerInvite} USD/Credit</p>
              </Col>
            )}
          </Row>
        </ModalBody>
        {/* <Divider variant="middle" />
        <ModalFooter>
          <Button color="info" onClick={toggle}>
            Close
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
}

export default PlanList;

const styles = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};
