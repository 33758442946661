import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Label, Input, Form } from "reactstrap";
import Select from "react-select";
import { getCountryList } from "action/plans";

function FilterOptions(props) {
  const planList = useSelector((state) => state.planReducer.plansTenantList);
  // const planList = useSelector((state) => state.planReducer.plansList.Plan);
  const countryList = useSelector(
    (state) => state.CountryReducer.countryList.data
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    getCountryList(dispatch);
  }, [dispatch]);

  const planOptions = planList?.data?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const accountType = [
    { value: "INTERNAL", label: "Internal" },
    { value: "EXTERNAL", label: "External" },
    { value: "", label: "All" },
  ];

  const statusOption = [
    { value: "ACTIVE", label: "Active" },
    { value: "NOT_VERIFIED", label: "Not Verified" },
    { value: "INACTIVE", label: "Inactive" },
    { value: "DELETED", label: "Deleted" },
  ];

  const countryOptions = countryList?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.handleSearch(); // Trigger search when Enter is pressed
    }
  };

  return (
    <Form onKeyDown={handleKeyPress}>
      <Row>
        <Col lg="2" md="2" sm="12">
          <Input
            type="text"
            style={{ height: "38px" }}
            placeholder="Search Name"
            value={props.searchData.name}
            onChange={props.handleNameChange}
            onKeyPress={props.handleKeyPress}
          />
        </Col>
        <Col lg="2" md="2" sm="12">
          <Input
            type="text"
            style={{ height: "38px" }}
            placeholder="Search Email"
            value={props.searchData.email}
            onChange={props.handleEmailChange}
            onKeyPress={props.handleKeyPress}
          />
        </Col>
        <Col lg="2" md="2" sm="12">
          <Select
            options={countryOptions}
            value={
              countryOptions?.find(
                (option) => option.value === props.searchData.countryName
              ) || null
            }
            onChange={props.handleCountryNameChange}
            placeholder="Select Country"
            isClearable
            styles={customStyles}
            onKeyDown={props.handleKeyPress}
          />
        </Col>
        <Col lg="2" md="2" sm="12">
          <Select
            options={planOptions}
            value={
              planOptions?.find(
                (option) => option.value === props.searchData.planName
              ) || null
            }
            onChange={props.handlePlanChange}
            placeholder="Select Plan"
            isClearable
            styles={customStyles}
            onKeyDown={props.handleKeyPress}
          />
        </Col>

        <Col lg="2" md="2" sm="12">
          <Select
            options={statusOption}
            value={statusOption?.find(
              (option) => option.value === props.searchData.status
            )}
            onChange={props.handleStatusChange}
            placeholder="Select Status"
            isClearable
            styles={customStyles}
            onKeyDown={props.handleKeyPress}
          />
        </Col>
        <Col lg="2" md="2" sm="12">
          <Select
            options={accountType}
            value={accountType?.find(
              (option) => option.value === props.searchData.accType
            )}
            onChange={props.handleAccountTypeChange}
            placeholder="Select Account Type"
            isClearable
            styles={customStyles}
            onKeyDown={props.handleKeyPress}
          />
        </Col>

        <Col lg="12" md="12" sm="12" className="mt-4">
          <Row className="d-flex align-items-center justify-content-start pr-3">
            <Row className="mx-3">
              <Label className="mt-2 text-muted">From</Label>

              <Input
                style={{ width: "140px", marginLeft: "5px", height: "40px" }}
                onChange={props.handleStartDateChange}
                value={props.searchData.startDate}
                type="date"
                id="startDate"
                name="startDate"
                max={
                  props.searchData.endDate ||
                  new Date().toISOString().split("T")[0]
                } // Set max date to today's date
                onKeyPress={props.handleKeyPress}
              />
              <Label className="mx-2 mt-2 text-muted">To</Label>
              <Input
                style={{ width: "140px", height: "40px" }}
                onChange={props.handleEndDateChange}
                value={props.searchData.endDate}
                type="date"
                id="endDate"
                name="endDate"
                min={props.searchData.startDate}
                max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                onKeyPress={props.handleKeyPress}
              />
            </Row>
            <Row>
              <Col className="d-flex justify-content-end pr-0">
                <Button
                  type="button"
                  outline
                  color="info"
                  style={{ height: "40px", paddingTop: "8px" }}
                  onClick={props.handleSearch}
                >
                  Search
                </Button>
              </Col>
              <Col>
                <Button
                  type="button"
                  outline
                  color="info"
                  style={{ height: "40px", paddingTop: "8px" }}
                  onClick={props.handleClear}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default FilterOptions;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px", // Font size for the input
    borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #DEE2E6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#DEE2E6", // Border color on hover
    },
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px", // Font size for dropdown items
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for dropdown
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8898aa", // Text color for the input
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
};
