import TimelineHeader from "components/Headers/SimpleHeader";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";

function CampaignLogs() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const logs = [
    {
      timestamp: "Oct 29, 2024 13:08:01.008 +05:30",
      log: `{"level":"info","RequestID":"","caller":"/home/pawan/go/src/github.com/bulkcalendar/bulk_calendar/services/core/service/cron.go:45","time":"2024-10-29T07:38:01Z","message":"core:services:cron: RetrySendingCampaigns completed for campaignCodes: "}`,
    },
    {
      timestamp: "Oct 29, 2024 13:08:01.008 +05:30",
      log: `{"level":"info","RequestID":"","caller":"/home/pawan/go/src/github.com/bulkcalendar/bulk_calendar/services/core/repo/cron.go:39","time":"2024-10-29T07:38:01Z","message":"core:cron:repo: GetRetryCampaignDetails completed"}`,
    },
    {
      timestamp: "Oct 29, 2024 13:08:01.004 +05:30",
      log: `{"level":"info","RequestID":"","caller":"/home/pawan/go/src/github.com/bulkcalendar/bulk_calendar/services/core/repo/cron.go:24","time":"2024-10-29T07:38:01Z","message":"core:cron:repo: GetRetryCampaignDetails started"}`,
    },
    {
      timestamp: "Oct 29, 2024 13:08:01.004 +05:30",
      log: `{"level":"info","RequestID":"","caller":"/home/pawan/go/src/github.com/bulkcalendar/bulk_calendar/services/core/service/cron.go:34","time":"2024-10-29T07:38:01Z","message":"core:services:cron: RetrySendingCampaigns started"}`,
    },
    {
      timestamp: "Oct 29, 2024 13:07:01.004 +05:30",
      log: `{"level":"info","RequestID":"","caller":"/home/pawan/go/src/github.com/bulkcalendar/bulk_calendar/services/core/repo/cron.go:39","time":"2024-10-29T07:37:01Z","message":"core:cron:repo: GetRetryCampaignDetails completed"}`,
    },
    {
      timestamp: "Oct 29, 2024 13:07:01.004 +05:30",
      log: `{"level":"info","RequestID":"","caller":"/home/pawan/go/src/github.com/bulkcalendar/bulk_calendar/services/core/repo/cron.go:24","time":"2024-10-29T07:37:01Z","message":"core:cron:repo: GetRetryCampaignDetails started"}`,
    },
  ];

  return (
    <>
      <TimelineHeader name="Campaign Logs" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row md={12} lg={12} className="d-flex justify-content-between">
              <Col xs={8} className="d-flex align-items-center">
                <h3 className="mb-0">
                  <i
                    className="fa fa-arrow-left mr-2 "
                    style={styles.cursor}
                    onClick={() => {
                      navigate(`/admin/tenants/campaign/details`, {
                        state: { id: id },
                      });
                    }}
                  />{" "}
                  Campaign Logs
                </h3>
              </Col>
              <Col
                xs={4}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
            <small>Note : This is static screen.</small>
          </CardHeader>
          <CardBody>
            {/* Headers */}
            <Row className="font-weight-bold mb-2">
              <Col md="4">Timestamp</Col>
              <Col md="8">Log</Col>
            </Row>

            {/* Logs */}
            {logs.map((logEntry, index) => (
              <Row key={index} className="py-2 border-top">
                <Col md="4" className="text-muted">
                  {logEntry.timestamp}
                </Col>
                <Col md="8">{logEntry.log}</Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CampaignLogs;
const styles = {
  cursor: { cursor: "pointer" },
};
