import axios from "axios";
import {
  PLAN,
  BASE_URL,
  PLANS_LIST,
  TENANT_LIST,
  COUNTRY_LIST,
  TENANT_DETAILS,
  PLAN_DETAILS,
  CAMPAIGNS_LIST,
  SUBUSER_LIST,
  PAYMENT_LIST,
  PLAN_WISE_USERS,
  CHANGE_TENANT_STATUS,
  REGENERATE_INVOICE,
  DOWNLOAD_INVOICE,
  EMAIL_CONFIG_LIST,
  CAMPAIGNS_DETAILS,
  ATTENDEE_LIST,
  REDIRECT_LINKS,
  TENANT_ACCOUNT_TYPE,
  ADD_CREDITS,
} from "./apiConstants";

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

//To download invoice pdf, needs to pass responseType and headers
const API_FOR_PFD = axios.create({
  baseURL: BASE_URL,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/pdf",
  },
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("p")) {
    req.headers.token = JSON.parse(localStorage.getItem("p")).token;
  }
  return req;
});

API_FOR_PFD.interceptors.request.use((req) => {
  if (localStorage.getItem("p")) {
    req.headers.token = JSON.parse(localStorage.getItem("p")).token;
  }
  return req;
});

//All plan related APIs
export const add_plan = (planData) => API.post(`${PLAN}`, planData);

export const get_plan = (planid) => API.get(`${PLAN}/${planid}`);

export const get_plans_list = () => API.get(`${PLANS_LIST}`);

export const update_plan = (planID, planUpdatedData) =>
  API.patch(`${PLAN}/${planID}`, planUpdatedData);

export const get_plan_wise_users = () => API.get(`${PLAN_WISE_USERS}`);

//All tenant related APIs
export const get_tenant_list = (query, num, sort) =>
  API.get(
    `${TENANT_LIST}?${query && `filtersJSON=${query}`}&page=${num}${sort}`
  );

export const change_account_type = (id, body) =>
  API.patch(`${TENANT_ACCOUNT_TYPE}/${id}`, body); //To change the account type of tenant

export const patch_tenant_details = (id, body) =>
  API.patch(`${TENANT_DETAILS}/${id}`, body);

export const change_tenant_status = (id, status) =>
  API.patch(`${CHANGE_TENANT_STATUS}/${id}`, status);

export const delete_tenant = (id) =>
  API.delete(`${CHANGE_TENANT_STATUS}/${id}`);

//All tenant details related APIs
export const get_tenant_details = (id) => API.get(`${TENANT_DETAILS}/${id}`);

export const get_tenant_plan_details = (id) => API.get(`${PLAN_DETAILS}/${id}`);

export const get_campaign_list = (id, filter, pageNum, sort) =>
  API.get(
    `${CAMPAIGNS_LIST}/${id}?${
      filter && `filtersJSON=${filter}`
    }&page=${pageNum}${sort}`
  );

export const get_subuser_list = (id, filter, pageNum, sort) =>
  API.get(
    `${SUBUSER_LIST}/${id}?filtersJSON=${filter}&page=${pageNum}&sortOptionJSON=${sort}`
  );

export const get_payment_list = (id, filter, pageNum, sort) =>
  API.get(
    `${PAYMENT_LIST}?tenantID=${id}&filtersJSON=${filter}&page=${pageNum}&sortOptionJSON=${sort}`
  );

export const regenerate_invoice = (invoiceID) =>
  API.get(`${REGENERATE_INVOICE}/${invoiceID}`);

export const download_invoice = (invoiceID, tenantID) =>
  API_FOR_PFD.get(`${DOWNLOAD_INVOICE}/${invoiceID}?tenantID=${tenantID}`);

export const get_email_config_list = (tenantID) =>
  API.get(`${EMAIL_CONFIG_LIST}?tenantID=${tenantID}`);

export const add_credits = (creditsData) =>
  API.post(`${ADD_CREDITS}`, creditsData);

//Redirect Links API
export const redirect_links = (pageNum, filter) =>
  API.get(`${REDIRECT_LINKS}?page=${pageNum}&filtersJSON=${filter}`);

//Others
export const get_country_list = () => API.get(`${COUNTRY_LIST}`);

//campaign Details
export const campaign_details = (campaignCode, tenantID) =>
  API.get(`${CAMPAIGNS_DETAILS}${campaignCode}?tenantID=${tenantID}`);

//attendee list
export const attendee_list = (
  campaignCode,
  tenantID,
  pageNo,
  filter,
  sortParam
) =>
  API.get(
    `${ATTENDEE_LIST}${campaignCode}?tenantID=${tenantID}&page=${pageNo}${
      filter ? filter : ""
    }${sortParam ? sortParam : ""}`
  );
