import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
  UncontrolledTooltip,
  Collapse,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

// import CustomPagination from "components/Common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { default as Select, components } from "react-select";
import { getAttendeeList } from "action/plans";
import CustomPagination from "components/CommonComponents/Pagination";

export default function CampaignAccordian({
  attendeeList,
  campaignDetails,
  campaignCode,
  tenantID,
}) {
  const dispatch = useDispatch();
  const [showReadMoreModal, setShowReadMoreModal] = React.useState(false);
  const [showReadMoreModalDesc, setShowReadMoreModalDesc] =
    React.useState(false);
  // const [modalState, setModalState] = useState({
  //   show: false,
  //   mode: "add",
  //   emailSendFields: {
  //     firstName: { value: "", isValid: true },
  //     email: { value: "", isValid: true },
  //     lastName: { value: "", isValid: true },
  //     field1: { value: "", isValid: true },
  //     field2: { value: "", isValid: true },
  //     field3: { value: "", isValid: true },
  //     field4: { value: "", isValid: true },
  //     field5: { value: "", isValid: true },
  //   },
  // });
  const [showListAlternative, setShowListAlternative] = useState({
    loader: false,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const [selectedMoreCols, setSelectedMoreCols] = useState([]);
  // const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [showLoaderOnSearch, setShowLoaderOnSearch] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
    status: null,
  });
  const [filterParams, setFilterParams] = useState("");
  const [alertModal, setAlertModal] = useState(null);
  const [openedCollapses, setoOpenedCollapses] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "DESC",
    sortParam: "",
  });

  //call to get attendee list
  useEffect(() => {
    //get attendee list based on selected
    if (campaignCode && pageNum) {
      getAttendeeList(
        dispatch,
        campaignCode,
        tenantID,
        pageNum,
        filterParams,
        sortConfig.sortParam
      );
    }
  }, [pageNum, campaignCode, filterParams, sortConfig.sortParam]);

  //setting the state of attendee list or in other case showing loader or not found msg
  useEffect(() => {
    if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
      setShowLoaderOnSearch(false);
    } else if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
      setShowLoaderOnSearch(false);
    }
  }, [attendeeList.CampaignAttendee]);

  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setoOpenedCollapses([]);
    } else {
      setoOpenedCollapses([collapse]);
    }
  };

  //for email body
  const handleReadMore = () => {
    setShowReadMoreModal(!showReadMoreModal);
  };

  //for calendar description
  const handleReadMoreDesc = () => {
    setShowReadMoreModalDesc(!showReadMoreModalDesc);
  };

  // //toggle the modal state of attendee modal
  // const toggleModalState = (mode, item) => {
  //   let attendeeData = {
  //     firstName: { value: "", isValid: true },
  //     email: { value: "", isValid: true },
  //     lastName: { value: "", isValid: true },
  //     field1: { value: "", isValid: true },
  //     field2: { value: "", isValid: true },
  //     field3: { value: "", isValid: true },
  //     field4: { value: "", isValid: true },
  //     field5: { value: "", isValid: true },
  //   };
  //   if (item) {
  //     attendeeData = {
  //       ID: item.ID,
  //       firstName: { value: item.firstName, isValid: true },
  //       email: { value: item.email, isValid: true },
  //       lastName: { value: item.lastName, isValid: true },
  //       field1: { value: item.field1, isValid: true },
  //       field2: { value: item.field2, isValid: true },
  //       field3: { value: item.field3, isValid: true },
  //       field4: { value: item.field4, isValid: true },
  //       field5: { value: item.field5, isValid: true },
  //     };
  //   }
  //   setModalState({
  //     show: !modalState.show,
  //     mode: mode,
  //     emailSendFields: attendeeData,
  //   });
  // };

  //when to manage the more add cols in attendee table
  // const handleChangeMoreColumns = (selected, e) => {
  //   setSelectedMoreCols(selected);
  // };

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = (height) => {
    return {
      control: (provided, state) => ({
        ...provided,
        borderColor: state.selectProps.className?.includes("is-invalid")
          ? "red"
          : "gray",
        height: height,
        minHeight: height,
        fontSize: "0.85rem",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "gray",
      }),
      menuList: (provided) => ({
        ...provided,
        fontSize: "0.8rem",
      }),
    };
  };

  //on change of filter in attendee list
  const handleFilterChange = (name, value) => {
    setFilter((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //search filters
  const handleSearch = () => {
    let queryParam = "";
    if (filter.search.trim().length > 0) {
      queryParam = "&";
      queryParam += `searchString=${filter.search.toString()}`;
    }
    if (filter.status !== null && filter.status.value !== "") {
      queryParam += `&filtersJSON=[{"field": "Status", "condition": "eq", "filterValues": [${JSON.stringify(
        filter.status.value
      )}]}]`;
    }
    setFilterParams(queryParam);
  };

  //on clear filters
  const handleClear = () => {
    setFilterParams("");
    setFilter({
      search: "",
      status: null,
    });
  };

  //in case of subject to replace again found any parameter other wise retunr only the actual value
  function reReplace(str) {
    const dynamicParmeter = [
      "{email-subject}",
      "{event-start-datetime}",
      "{event-end-datetime}",
      "{event-meet-url}",
      "{event-location}",
      "{unsubscribe-link}",
    ];
    // Placeholder mapping
    const replacements = {
      "{event-start-datetime}": campaignDetails?.startAt,
      "{event-end-datetime}": campaignDetails?.endAt,
      "{event-meet-url}": campaignDetails?.eventData?.onlineEventUrl,
      "{event-location}": campaignDetails?.eventData?.locationName,
    };

    // Base case: If no dynamic parameters are found, return the string
    const foundPlaceholder = dynamicParmeter.find((param) =>
      str?.includes(param)
    );
    if (!foundPlaceholder) {
      return str; // No placeholders found, return the final string
    }
    // Recursive case: Replace the found placeholder and recurse
    const replacedString = str.replace(
      foundPlaceholder,
      replacements[foundPlaceholder] || ""
    );
    // Call the function recursively until no more placeholders are found
    return reReplace(replacedString);
  }

  const getReplacedValue = (text) => {
    // Placeholder mapping
    const replacements = {
      "{email-subject}": reReplace(campaignDetails?.emailSubject),
      "{event-start-datetime}": campaignDetails?.startAt,
      "{event-end-datetime}": campaignDetails?.endAt,
      "{event-meet-url}": campaignDetails?.eventData?.onlineEventUrl,
      "{event-location}": campaignDetails?.eventData?.locationName,
      "{unsubscribe-link}": `<span style="color: blue; cursor: pointer;">Unsubscribe</span>`,
    };

    // Replace placeholders using a regular expression
    //The regular expression /\{[^\}]+\}/g matches any text within curly braces {}.
    //The callback function checks if the matched placeholder exists in the replacements map
    // and replaces it accordingly. If no replacement is found, the original placeholder is returned.
    return text?.replace(
      /\{[^\}]+\}/g,
      (match) => replacements[match] || match
    );
  };

  const emailBody = getReplacedValue(campaignDetails?.emailBody) || "";
  const previewLength = 50;
  const isLong = emailBody.length > previewLength;
  const preview = isLong
    ? emailBody.slice(0, previewLength) + "..."
    : emailBody;
  //for calendar  description
  const calendarDescription =
    getReplacedValue(campaignDetails?.calenderDescription) || "";
  const isLongDesc = calendarDescription.length > previewLength;
  const previewDesc = isLongDesc
    ? calendarDescription.slice(0, previewLength) + "..."
    : calendarDescription;

  //for handling sor t by col and its direction
  const handleSort = (colname) => {
    let newDirection = sortConfig.direction === "ASC" ? "DESC" : "ASC";
    setSortConfig({
      key: colname,
      direction: newDirection,
      sortParam: `&sortOptionJSON={"SortBy":"${colname}","SortType":"${newDirection}"}`,
    });
  };

  return (
    <div className="pb-10">
      {alertModal}
      <Modal isOpen={showReadMoreModal} size="lg" scrollable centered>
        <ModalHeader
          toggle={() => {
            handleReadMore();
          }}
        >
          Email
        </ModalHeader>
        <ModalBody className="pt-0">
          <Label>Email Subject</Label>
          <p className="button">
            {getReplacedValue(campaignDetails?.emailSubject)}
          </p>
          <Label>Email Body</Label>

          {/* Render HTML content with dangerouslySetInnerHTML */}
          <div
            dangerouslySetInnerHTML={{
              __html: emailBody,
            }}
          />
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button onClick={handleReadMore} color="info">
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showReadMoreModalDesc} size="lg" scrollable centered>
        <ModalHeader
          toggle={() => {
            handleReadMoreDesc();
          }}
        >
          Calendar
        </ModalHeader>
        <ModalBody className="pt-0">
          <Label>Calendar Title</Label>
          <p className="button">
            {getReplacedValue(campaignDetails?.calendarTitle)}
          </p>
          <Label>Calendar Body</Label>
          <p className="button">{calendarDescription}</p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button onClick={handleReadMoreDesc} color="info">
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div className="accordion">
        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => collapsesToggle("collapseOne")}
            aria-expanded={openedCollapses.includes("collapseOne")}
          >
            <h3 className="mb-0">Event Details</h3>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={openedCollapses.includes("collapseOne")}
          >
            <CardBody>
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Event Type</Label>
                  <p className="button">
                    {campaignDetails?.eventType === "ONLINE"
                      ? "Online"
                      : campaignDetails?.eventType === "OFFLINE"
                      ? "Offline"
                      : ""}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>
                    Event
                    {campaignDetails?.eventType === "ONLINE"
                      ? " Meet URL"
                      : " Location"}
                  </Label>
                  <p className="button">
                    {campaignDetails?.eventType === "ONLINE"
                      ? campaignDetails?.eventData?.onlineEventUrl
                      : campaignDetails?.eventData?.locationName}
                  </p>
                </Col>
              </Row>
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Calendar Title</Label>
                  <p className="button">
                    {getReplacedValue(campaignDetails?.calendarTitle)}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>Calendar Description</Label>
                  <p className="button">
                    {previewDesc}
                    {isLongDesc && (
                      <span
                        onClick={handleReadMoreDesc}
                        style={{
                          color: "gray",
                          cursor: "pointer",
                          fontSize: "0.9rem",
                        }}
                      >
                        Read More
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Email Subject</Label>
                  <p className="button">
                    {getReplacedValue(campaignDetails?.emailSubject)}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>Email Body</Label>

                  {/* Render HTML content with dangerouslySetInnerHTML */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: preview,
                    }}
                  />
                  {isLong && (
                    <span
                      onClick={() => handleReadMore()}
                      style={{
                        color: "gray",
                        cursor: "pointer",
                        fontSize: "0.9rem",
                      }}
                    >
                      Read More
                    </span>
                  )}
                </Col>
              </Row>
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Created by </Label>
                  <p className="button">
                    {`${campaignDetails?.createdBy?.firstName} ${campaignDetails?.createdBy?.lastName}`}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>Created At </Label>
                  <p className="button">{campaignDetails?.createdAt}</p>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => collapsesToggle("collapseTwo")}
            aria-expanded={openedCollapses.includes("collapseTwo")}
          >
            <h3 className="mb-0">Attendee List</h3>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={openedCollapses.includes("collapseTwo")}
          >
            <CardBody>
              <Row className="p-0 align-items-center mb-4">
                <Col
                  xs={12}
                  md={7}
                  className="d-flex flex-wrap justify-content-start align-items-center p-0"
                >
                  {/* Search Input */}
                  <Col xs={12} sm={6} md={5} className="mb-2 mb-md-0 pr-md-0">
                    <InputGroup>
                      <InputGroupText
                        style={{
                          borderTopRighttRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <i className="fa fa-search" />
                        </InputGroupAddon>
                      </InputGroupText>
                      <Input
                        placeholder="Search"
                        type="text"
                        name="search"
                        autoComplete="off"
                        value={filter.search}
                        onChange={(e) =>
                          handleFilterChange(e.target.name, e.target.value)
                        }
                      />
                    </InputGroup>
                  </Col>
                  {/* Select Status */}
                  <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0 pr-md-0">
                    <Select
                      options={[
                        { label: "Unsent", value: "UNSEND" },
                        { label: "Sent", value: "SENT" },
                        { label: "Don't Send", value: "DONT_SEND" },
                        { label: "Unsubscribed", value: "UNSUBSCRIBED" },
                        { label: "Failed", value: "FAILED" },
                      ]}
                      placeholder="Search by status"
                      onChange={(selected, e) =>
                        handleFilterChange("status", selected)
                      }
                      styles={customStyles("2.9rem")}
                      value={filter.status}
                      isSearchable={true} // enable search functionality
                      isClearable
                    />
                  </Col>
                  {/* Search Button */}
                  <Col xs="auto" className="mb-2 mb-md-0">
                    <Button
                      className="btn-round btn-icon"
                      color="info"
                      outline
                      onClick={(e) => handleSearch()}
                      size="md"
                      disabled={showLoaderOnSearch}
                    >
                      {showLoaderOnSearch ? (
                        <i className="fa fa-spinner fa-spin fa-lg text-info px-3" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Col>
                  {/* Clear Button */}
                  {filterParams !== "" && (
                    <Col xs="auto" className="mb-2 mb-md-0 p-0">
                      <Button
                        className="btn-round btn-icon"
                        color="light"
                        outline
                        onClick={() => handleClear()}
                        size="md"
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Col>
              </Row>
              {showListAlternative.loader ? (
                <div style={styles.loaderContainer}>
                  <i className="fa fa-spinner fa-spin fa-2xl text-info" />
                </div>
              ) : showListAlternative.noRecordsFound ? (
                <div style={styles.loaderContainer}>No Records Found!</div>
              ) : (
                <>
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Sr. no</th>
                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("Email")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            Email ID
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "Email" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "Email" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>

                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("FirstName")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            First Name
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "FirstName" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "FirstName" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>
                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("LastName")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            Last Name
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "LastName" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "LastName" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>
                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("SentOn")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            Sent On
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "SentOn" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "SentOn" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>
                        <th>Status</th>

                        {selectedMoreCols.length > 0 &&
                          selectedMoreCols.map((colItem) => {
                            return <th key={colItem.label}>{colItem.label}</th>;
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {attendeeList.CampaignAttendee?.map((item, index) => {
                        return (
                          <tr
                            style={{
                              backgroundColor: item.checked ? "blue" : "white",
                            }}
                            key={item.email}
                          >
                            <td className="text-muted">{index + 1}</td>
                            <td className="text-muted">
                              <span>{item.email}</span>
                            </td>
                            <td className="text-muted">
                              <span>{item.firstName}</span>
                            </td>
                            <td className="text-muted">
                              <span>{item.lastName}</span>
                            </td>
                            <td className="text-muted">
                              {item.sentOn !== "" ? (
                                <span>{item.sentOn}</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {item.status === "UNSEND" ? (
                                <span className="text-muted">Unsent</span>
                              ) : item.status === "SENT" ? (
                                <span className="text-success font-weight-bold">
                                  Sent
                                </span>
                              ) : item.status === "DONT_SEND" ? (
                                <span className="text-primary">Don't Send</span>
                              ) : item.status === "UNSUBSCRIBED" ? (
                                <span className="text-orange">
                                  Unsubscribed
                                </span>
                              ) : item.status === "FAILED" ? (
                                <span className="text-red">Failed</span>
                              ) : (
                                <span className="text-muted">
                                  {item.status}
                                </span>
                              )}
                            </td>
                            {selectedMoreCols.length > 0 &&
                              selectedMoreCols.map((colItem) => {
                                return (
                                  <td
                                    className="text-muted"
                                    key={colItem.value}
                                  >
                                    {item[colItem.value]}
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div>
                    <CustomPagination
                      totalPages={Math.ceil(
                        attendeeList.totalRecords /
                          attendeeList.noOfRecordsPerPage
                      )}
                      setPageNum={setPageNum}
                      activePage={pageNum}
                    />
                  </div>
                </>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </div>
  );
}

const styles = {
  cusrorPointer: { cursor: "pointer" },
  iconSpacing: { flexDirection: "row", columnGap: 8 },
  cursor: { cursor: "pointer" },
  csvExampleInnerText: { cursor: "pointer", fontSize: 14 },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  actionConatiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "1rem",
  },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  sortIcon: {
    fontSize: "1.5em",
    transform: "scale(1.2)",
  },
};
