import { getCountryList } from "action/plans";
import TimelineHeader from "components/Headers/SimpleHeader";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import { redirectLinks } from "action/plans";
import CustomPagination from "components/CommonComponents/Pagination";
function RedirectLinks() {
  const columns = [
    {
      dataField: "ID",
      text: "Id",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "created at",
      sort: true,
    },
    {
      dataField: "ipAddress",
      text: "Ip Address",
      sort: true,
    },
    {
      dataField: "headers",
      text: "Header",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length >= 20) {
          let temp = cell.substring(0, 20) + "...";
          return (
            <>
              {temp}
              <span
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => handleHeaderModal(row.headers)} // Pass header data
                onMouseOver={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                className="text-blue"
              >
                ...view more
              </span>
            </>
          );
        } else return cell;
      },
    },
    {
      dataField: "referer",
      text: "Referrer",
      sort: true,
    },
    {
      dataField: "userAgent",
      text: "User Agent",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length >= 20) {
          let temp = cell.substring(0, 30) + "...";
          return (
            <>
              <span id={`tooltipHeader${row.ID}`}>{temp}</span>
              <UncontrolledTooltip
                autohide={false}
                target={`tooltipHeader${row.ID}`}
              >
                {cell}
              </UncontrolledTooltip>
            </>
          );
        } else return cell;
      },
    },
  ];
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [headerData, setHeaderData] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const dispatch = useDispatch();
  const redirectLinksList = useSelector(
    (state) => state.RedirectLinksReducer.redirectLinks
  );
  const [searchData, setSearchData] = React.useState({
    ipaddress: "",
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    referer: "",
    currentDate: new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    setIsLoading(true);
    let temp = [
      {
        field: "CreatedAt",
        condition: "btw",
        filterValues: [searchData.currentDate, searchData.currentDate],
      },
    ];
    redirectLinks(
      dispatch,
      pageNum,
      filterQuery ? filterQuery : JSON.stringify(temp)
    );
  }, [pageNum]);

  useEffect(() => {
    if (redirectLinksList.code) {
      if (redirectLinksList.code === 200) {
        setIsError(false);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    } else {
      setIsError(false);
      setIsLoading(true);
    }
  }, [redirectLinksList]);

  const handleHeaderModal = (headerData) => {
    setHeaderData(headerData);
    toggle();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSearchData((prevData) => {
      // Automatically set endDate to today's date if startDate is selected and endDate is empty
      if (name === "startDate" && !prevData.endDate) {
        return {
          ...prevData,
          [name]: value,
          endDate: new Date().toISOString().split("T")[0], // Set endDate to current date
        };
      }

      // Clear endDate if the selected startDate is after the current endDate
      if (
        name === "startDate" &&
        prevData.endDate &&
        new Date(prevData.endDate) < new Date(value)
      ) {
        return { ...prevData, [name]: value, endDate: value };
      }

      return { ...prevData, [name]: value };
    });
  };

  const getFilterCondition = (field, value) => {
    switch (field) {
      case "Referer":
        return { field: "Referer", condition: "like", filterValues: [value] };
      case "IpAddress":
        return {
          field: "IpAddress",
          condition: "like",
          filterValues: [value],
        };
      case "CreatedAt":
        if (searchData.startDate && searchData.endDate) {
          return {
            field: "CreatedAt",
            condition: "btw",
            filterValues: [searchData.startDate, searchData.endDate],
          };
        } else if (searchData.startDate) {
          return {
            field: "CreatedAt",
            condition: "gt",
            filterValues: [searchData.startDate],
          };
        } else if (searchData.endDate) {
          return {
            field: "CreatedAt",
            condition: "lt",
            filterValues: [searchData.endDate],
          };
        }
        break;
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const textQuery = [
      searchData.referer && getFilterCondition("Referer", searchData.referer),
      searchData.ipaddress &&
        getFilterCondition("IpAddress", searchData.ipaddress),
      (searchData.startDate || searchData.endDate) &&
        getFilterCondition("CreatedAt"),
    ].filter(Boolean);

    const temp = JSON.stringify(textQuery);
    setFilterQuery(temp);
    setPageNum(1);
    redirectLinks(dispatch, 1, temp);
  };

  const handleClear = () => {
    let date = new Date().toISOString().split("T")[0];
    let temp = [
      {
        field: "CreatedAt",
        condition: "btw",
        filterValues: [date, date],
      },
    ];
    setFilterQuery("");
    setPageNum(1);
    setIsLoading(true);
    setSearchData({
      ipaddress: "",
      startDate: date,
      endDate: date,
      referer: "",
      currentDate: date,
    });
    redirectLinks(dispatch, 1, JSON.stringify(temp));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSubmit();
  };

  return (
    <>
      <TimelineHeader />
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
        style={style.modal}
      >
        <ModalHeader className="mb--2" toggle={toggle}>
          Header Details
        </ModalHeader>
        <div
          style={{
            padding: "20px",
            overflowY: "auto",
            maxHeight: "500px", // Limit vertical height
            whiteSpace: "pre-wrap",
            wordWrap: "break-word", // Wrap long words to prevent overflow
          }}
        >
          {headerData}
        </div>
      </Modal>

      <Container fluid className="mt--5">
        <Card>
          <CardHeader>Redirect Links</CardHeader>
          <CardBody className="mb--4 pb-0" onKeyDown={(e) => handleKeyDown(e)}>
            <Row>
              <Col lg="2" md="2" sm="12" className="mr--2">
                <Input
                  type="text"
                  name="referer"
                  style={{ height: "38px" }}
                  placeholder="Search Referrer"
                  value={searchData.referer}
                  onChange={(e) => {
                    // handleReferrerChange(e);
                    handleChange(e);
                  }}
                />
              </Col>
              <Col lg="2" md="2" sm="12">
                <Input
                  type="text"
                  name="ipaddress"
                  style={{ height: "38px" }}
                  placeholder="Search Ipaddress"
                  value={searchData.ipaddress}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Col>
              <Col>
                <Row>
                  <Label className="mt-2 text-muted">From</Label>
                  <Input
                    style={{
                      width: "140px",
                      marginLeft: "5px",
                      height: "38px",
                    }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={searchData.startDate}
                    type="date"
                    id="startDate"
                    name="startDate"
                    max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                  />
                  <Label className="mx-2 mt-2 text-muted">To</Label>
                  <Input
                    style={{ width: "140px", height: "38px" }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={searchData.endDate}
                    type="date"
                    id="endDate"
                    name="endDate"
                    min={searchData.startDate}
                    max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                  />
                  <Col>
                    <Button
                      type="button"
                      outline
                      color="info"
                      className=""
                      style={{
                        height: "40px",
                        paddingTop: "8px",
                      }}
                      onClick={handleSubmit}
                    >
                      Search
                    </Button>
                    <Button
                      type="button"
                      outline
                      color="info"
                      style={{
                        height: "40px",
                        paddingTop: "8px",
                      }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          {isLoading ? (
            <div style={style.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={style.loaderColor}
              />
            </div>
          ) : isError ? (
            <>
              <div style={style.loaderContainer}>Something went wrong</div>
            </>
          ) : redirectLinksList?.data?.totalRecords ? (
            <>
              <CardBody>
                <ToolkitProvider
                  data={redirectLinksList.data.UserRequest}
                  keyField="ID"
                  columns={columns}
                >
                  {(props) => (
                    <div className="py-4 overflow-hidden table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CustomPagination
                totalPages={Math.ceil(
                  redirectLinksList?.data?.totalRecords /
                    redirectLinksList?.data?.noOfRecordsPerPage
                )}
                setPageNum={setPageNum}
                activePage={pageNum}
              />
            </>
          ) : (
            <div style={style.loaderContainer}>No Records Found</div>
          )}
        </Card>
      </Container>
    </>
  );
}

export default RedirectLinks;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px", // Font size for the input
    borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #DEE2E6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#DEE2E6", // Border color on hover
    },
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px", // Font size for dropdown items
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for dropdown
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8898aa", // Text color for the input
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8898aa",
  }),
};
