import React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { IconButton, Tabs } from "@mui/material";
import { Card, Col, Row, CardBody, Container } from "reactstrap";
import OrgDetails from "./components/OrgDetails";
import PlanDetails from "./components/PlanDetails";
import TenantListing from "./components/SubUserListing";
import PaymentHistory from "./components/PaymentHistory";
import CampaignListing from "./components/CampaignListing";
import { useNavigate } from "react-router-dom";
import EmailConfiguration from "./components/EmailConfiguration";
import TimelineHeader from "components/Headers/SimpleHeader";
function DetailsTab() {
  const [value, setValue] = React.useState("ORGANIZATION_DETAILS");
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/admin/tenants");
  };
  const handleTabChange = (e, value) => {
    setValue(value);
  };

  return (
    <>
      <TimelineHeader
        showBack={true}
        isDetails={true}
        onClickBack={handleBackButton}
      />
      <Container fluid className="mt--4 ">
        <Card className="mb-1">
          {/* <CardBody> */}
          <Row lg="12" md="12" sm="12">
            <Col lg="12" md="12" sm="12">
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleTabChange}
                className="border-bottom bg-white p-2 pt-3"
                style={{ borderRadius: "0.4rem" }}
              >
                <Tab
                  label="Organization Details"
                  value="ORGANIZATION_DETAILS"
                />
                <Tab label="Plan Details" value="PLAN_DETAILS" />
                <Tab label="Campaigns" value="CAMPAIGN_LISTING" />
                {/* <Tab label="Sub-Users" value="TENANT_LISTING" /> */}
                {/* <Tab label="Payment History" value="PAYMENT_HISTORY" /> */}
                <Tab label="Email Configuration" value="EMAIL_CONFIGURATION" />
              </Tabs>
            </Col>
          </Row>
          {/* </CardBody> */}
        </Card>
      </Container>

      {value === "ORGANIZATION_DETAILS" && (
        <Box className="p-4">
          <OrgDetails />
          <TenantListing />
        </Box>
      )}
      {value === "PLAN_DETAILS" && (
        <Box className="p-4">
          <PlanDetails />
          <PaymentHistory />
        </Box>
      )}
      {value === "CAMPAIGN_LISTING" && (
        <Box className="p-4">
          <CampaignListing />
        </Box>
      )}
      {value === "TENANT_LISTING" && (
        <Box className="p-4">
          <TenantListing />
        </Box>
      )}
      {/* {value === "PAYMENT_HISTORY" && (
        <Box className="p-4">
          <PaymentHistory />
        </Box>
      )} */}
      {value === "EMAIL_CONFIGURATION" && (
        <Box className="p-4">
          <EmailConfiguration />
        </Box>
      )}
      {/* </Col> */}
      {/* </Row> */}
      {/* </CardBody> */}
      {/* </Card> */}
      {/* </Container> */}
    </>
  );
}

export default DetailsTab;
