import React, { useState, useEffect } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { formatDateWithTime } from "../SideComponents/DateTime";
import {
  Col,
  Row,
  Label,
  Input,
  Button,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import { getSubuserList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPaymentList } from "action/plans";
import { cyanBlue } from "../constants/Constants";
import CustomPagination from "../../../components/CommonComponents/Pagination";
import Swal from "sweetalert2";
import { regenerateInvoice } from "action/plans";
import { downloadInvoice } from "action/plans";
import { convertUTCToLocal } from "pages/CommonFunctions";
import { Backdrop } from "@mui/material";
import { GetPaymentList } from "constants/ReducerConstants/Constants";
function PaymentHistory() {
  const data = useSelector((state) => state.TenantDetailsReducer.paymentList);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state?.id;
  const [queryString, setQueryString] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  // const [searchData, setSearchData] = useState({
  //   Status: null,
  //   startDate: "",
  //   endDate: "",
  // });

  // const [statusOptions, setStatusoptions] = useState([
  //   { value: "ACTIVE", label: "ACTIVE" },
  //   { value: "IN-ACTIVE", label: "IN-ACTIVE" },
  // ]);

  const columns = [
    {
      dataField: "invoiceID",
      text: "invoiceID",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: style.colsWidth,
    },
    {
      dataField: "planName",
      text: "planName",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: style.colsWidth,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      style: style.colsWidth,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "allocatedCredits",
      text: "allocated Credits",
      sort: true,
      style: style.colsWidth,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "createdAt",
      text: "created At",
      sort: true,
      style: style.colsWidth,
      headerAlign: "center",
      align: "center",
      formatter: (cell) => {
        return convertUTCToLocal(cell);
      },
    },
    // {
    //   dataField: "credits",
    //   text: "CREDITS",
    //   sort: true,
    //   style: { width: "150px" },

    //   formatter: (cell) => {
    //     return (
    //       <div className="d-flex justify-content-start align-items-center">
    //         {cell}
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "pdflink",
      text: "Actions",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: style.colsWidth,
      formatter: (cell, row) => {
        return (
          <div style={style.actionsStyle}>
            <a
              id={`tooltipDownloadInvoice${row.planName}`}
              onClick={() => {
                handleDownloadInvoice(row.invoiceID, row.pdfName);
              }}
            >
              <i className="fa fa-download text-lg text-info"></i>
            </a>
            <UncontrolledTooltip
              delay={0}
              target={`tooltipDownloadInvoice${row.planName}`}
            >
              Download Invoice
            </UncontrolledTooltip>
            <div className="text-gray">|</div>
            <a
              id={`tooltipInvoice${row.planName}`}
              onClick={() => {
                handleRegenerateInvoice(row.invoiceID);
              }}
            >
              <i className="fa-solid fa-file-invoice text-lg text-info"></i>
            </a>
            <UncontrolledTooltip
              delay={0}
              target={`tooltipInvoice${row.planName}`}
            >
              Regenerate Invoice
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: GetPaymentList, payload: {} });
    getPaymentList(dispatch, id, queryString, pageNum, "");
  }, [dispatch, id, pageNum]);

  // useEffect(() => {
  //   if (data) setIsLoading(false);
  // }, [data]);

  React.useEffect(() => {
    if (data?.code) {
      if (data?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [data]);

  const handleRegenerateInvoice = async (invoiceID) => {
    Swal.fire({
      text: `Are you sure you want to regenerate the invoice?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      icon: "warning",
      focusCancel: true,
      customClass: {
        confirmButton: "bg-info",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire({
        //   title: "Regenerating invoice",
        //   text: `Please wait....`,
        //   showConfirmButton: false,
        // });
        generateInvoice(invoiceID);
      }
    });
  };

  const generateInvoice = async (invoiceID) => {
    setRegenerateLoading(true);
    const res = await regenerateInvoice(invoiceID);
    setRegenerateLoading(false);
    if (res.code === 200) {
      Swal.fire({
        title: `Invoice is regenerated successfully`,
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Failed to regenerate the invoice.`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleDownloadInvoice = async (invoiceID, pdfName) => {
    const res = await downloadInvoice(invoiceID, id);
    if (res.data) {
      // Create a Blob from the response data
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary link to trigger the download
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pdfName); // Filename for the downloaded PDF
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Failed to download the invoice.`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  // const handleSearch = () => {
  //   const query = Object.keys(searchData)
  //     .map((key) => {
  //       if (key === "startDate" || key === "endDate") {
  //         return null;
  //       } else if (searchData[key]) {
  //         return {
  //           field: key,
  //           condition: "eq",
  //           filterValues: [searchData[key]],
  //         };
  //       }
  //       return null;
  //     })
  //     .filter((item) => item !== null);

  //   if (searchData.startDate && searchData.endDate) {
  //     query.push({
  //       field: "CreatedAt",
  //       condition: "btw",
  //       filterValues: [searchData.startDate, searchData.endDate],
  //     });
  //   } else {
  //     if (searchData.startDate) {
  //       query.push({
  //         field: "CreatedAt",
  //         condition: "gt",
  //         filterValues: [searchData.startDate],
  //       });
  //     }

  //     if (searchData.endDate) {
  //       query.push({
  //         field: "CreatedAt",
  //         condition: "lt",
  //         filterValues: [searchData.endDate],
  //       });
  //     }
  //   }
  //   setIsLoading(true);
  //   setQueryString(JSON.stringify(query));
  //   setPageNum(1);
  // };

  // const handleClear = () => {
  //   setSearchData({
  //     Status: null,
  //     startDate: "",
  //     endDate: "",
  //   });
  //   setIsLoading(true);
  //   getPaymentList(dispatch, id, "", 1, "");
  // };

  // const handleStatusChange = (selectedOption) => {
  //   setSearchData((prev) => ({
  //     ...prev,
  //     Status: selectedOption,
  //   }));
  // };

  // const handleStartDateChange = (e) => {
  //   setSearchData((prevData) => ({ ...prevData, startDate: e.target.value }));
  //   if (
  //     searchData.endDate &&
  //     new Date(searchData.endDate) < new Date(e.target.value)
  //   ) {
  //     setSearchData((prevData) => ({ ...prevData, endDate: "" }));
  //   }
  // };

  // const handleEndDateChange = (e) => {
  //   setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  // };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={regenerateLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>
      <Card style={style.cardStyle}>
        <CardBody>
          <Col lg="12" className="mb-2 pb-3">
            <Row lg="12" md="12" sm="12">
              <Col lg="12" md="12" sm="12">
                <Row className="ml-0 justify-content-between">
                  <h3>Payment History</h3>
                </Row>
                <Row lg="12" md="12" sm="12">
                  {isLoading ? (
                    <div style={style.loaderContainer}>
                      <i
                        className="fa fa-spinner fa-spin fa-2xl"
                        style={style.loaderColor}
                      />
                    </div>
                  ) : error ? (
                    <>
                      <div style={style.loaderContainer}>
                        Something went wrong
                      </div>
                    </>
                  ) : (
                    <>
                      {data.data.totalRecords ? (
                        <ToolkitProvider
                          data={data.data.listPaymentHistory}
                          keyField="invoiceID"
                          columns={columns}
                          headerAlign="center"
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      ) : (
                        <div style={style.loaderContainer}>
                          <p style={style.loaderText}>No Record Found</p>
                        </div>
                      )}
                    </>
                  )}
                </Row>
                <CustomPagination
                  totalPages={Math.ceil(
                    data?.data?.totalRecords / data?.data?.noOfRecordsPerPage
                  )}
                  setPageNum={setPageNum}
                  activePage={pageNum}
                />
              </Col>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
  );
}

export default PaymentHistory;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  colsWidth: { width: "12rem" },
  cardStyle: { marginLeft: "0.4rem", marginRight: "0.4rem" },
  actionsStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: "0.5rem",
  },
};
